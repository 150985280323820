<div class="container" *ngIf="formReadyToShow">
    <div class="col-md-12 my-4">
        <div class="card card-body bg-light">
            <div *ngIf="!editable">
              <div style="font-size: 22px">{{sts.c('books.under_editing')}}</div>
              <p>{{sts.c('books.under_editing_current_editor')}} {{currentEditor}}</p>
              <a (click)="refreshPage()"
                 class="btn btn-default" > {{sts.c('books.under_editing_reload')}}</a>

            </div>
            <form  *ngIf="editable" novalidate class="form-horizontal" (ngSubmit)="createOrUpdate()" [formGroup]="mainForm"
                  autocomplete="off">
                <fieldset>

                    <app-formheader [formType]="formType" [formName]="formName" [id]="userId"
                                    [sts]="sts"></app-formheader>


                    <app-forminput [inputType]="'text'" [required]="true" [inputMinLength]="'3'" [control]="nameControl"
                                   [inputName]="'name'" [title]="'books.name'" [sts]="sts"
                                   [parentFormGroup]="mainForm" [permissions]="permissions"
                                   [higherRoleIsNeeded]="higherRoleIsNeeded"
                                   [readonly]="readonly"
                                   [actionInProgress]="actionInProgress"></app-forminput>

                    <app-forminput [inputType]="'text'" [required]="true" [inputMinLength]="'3'" [control]="keyControl"
                                   [inputName]="'key'" [title]="'books.key'" [sts]="sts"
                                   [parentFormGroup]="mainForm" [permissions]="permissions"
                                   [higherRoleIsNeeded]="higherRoleIsNeeded"
                                   [readonly]="readonly"
                                   [actionInProgress]="actionInProgress"></app-forminput>


                    <div class="form-group text-left" *ngIf="parentId && !shoppingId">
                        <label class="col-lg-12 control-label">{{sts.c('books.original_copy')}}:</label>
                        <div class="col-lg-12">
                            <a *ngIf="!parentIsDeleted" href="/admin/books/{{parentUrlType}}/{{parentId}}"
                               target="_blank">{{parentName}}</a>
                            <span *ngIf="parentIsDeleted">{{parentName}}</span>
                        </div>
                    </div>


                    <app-formtextarea [inputMinLength]="'3'" [control]="descriptionControl"
                                      [inputName]="'description'" [title]="'books.description'" [sts]="sts"
                                      [parentFormGroup]="mainForm" [permissions]="permissions"
                                      [higherRoleIsNeeded]="higherRoleIsNeeded"
                                      [readonly]="readonly"
                                      [actionInProgress]="actionInProgress"></app-formtextarea>


                    <app-formtextarea [inputMinLength]="'3'" [inputMaxLength]="'150'"
                                      [control]="shortDescriptionControl"
                                      [inputName]="'shortDescription'" [title]="'books.short_description'" [sts]="sts"
                                      [parentFormGroup]="mainForm" [permissions]="permissions"
                                      [higherRoleIsNeeded]="higherRoleIsNeeded" [actionInProgress]="actionInProgress"
                                      [readonly]="readonly"
                                      [toolTipOptions]="shortDescriptionToolTipOptions">
                    </app-formtextarea>

                    <app-forminput [inputType]="'text'" [control]="linkControl"
                                   [inputName]="'link'" [title]="'books.link'" [sts]="sts"
                                   [parentFormGroup]="mainForm" [permissions]="permissions"
                                   [higherRoleIsNeeded]="higherRoleIsNeeded"
                                   [readonly]="readonly"
                                   [actionInProgress]="actionInProgress" [toolTipOptions]="linkToolTipOptions"></app-forminput>


                    <app-formmultiselect [itemList]="languagesItemList" [itemSettings]="languagesSettings"
                                         [title]="'books.language'" [sts]="sts" [formControlNameString]="'language'"
                                         [required]="true"
                                         [control]="languageControl"
                                         [parentFormGroup]="mainForm"
                                         [readonly]="readonly"
                                         [actionInProgress]="actionInProgress"></app-formmultiselect>

                    <app-formmultiselect [itemList]="categoriesItemList" [itemSettings]="categoriesSettings"
                                         [title]="'books.category'" [sts]="sts" [formControlNameString]="'category'"
                                         [required]="true"
                                         [control]="categoryControl"
                                         [parentFormGroup]="mainForm"
                                         [readonly]="readonly"
                                         [actionInProgress]="actionInProgress"></app-formmultiselect>

                    <app-formmultiselect [itemList]="editorsItemList" [itemSettings]="editorsSettings"
                                         [title]="'books.editors'" [sts]="sts" [formControlNameString]="'editor'"
                                         [required]="true"
                                         [control]="editorControl"
                                         [parentFormGroup]="mainForm"
                                         [readonly]="readonly"
                                         [actionInProgress]="actionInProgress"></app-formmultiselect>

                    <app-formswitch [control]="marketplaceControl" [inputName]="'marketplace'"
                                    [title]="'books.marketplace'" [sts]="sts"
                                    [parentFormGroup]="mainForm" [permissions]="permissions"
                                    [higherRoleIsNeeded]="higherRoleIsNeeded"
                                    [readonly]="readonly"
                                    [actionInProgress]="actionInProgress">
                    </app-formswitch>

                    <app-formswitch [control]="publishedControl" [inputName]="'published'"
                                    [title]="'books.published'" [sts]="sts"
                                    [parentFormGroup]="mainForm" [permissions]="permissions"
                                    [higherRoleIsNeeded]="higherRoleIsNeeded"
                                    [readonly]="readonly"
                                    [actionInProgress]="actionInProgress">
                    </app-formswitch>
                  <app-formswitch [control]="arCameraContentControl" [inputName]="'arCameraContent'"
                                  [title]="'books.ar_camera_content'" [sts]="sts"
                                  [parentFormGroup]="mainForm" [permissions]="permissions"
                                  [higherRoleIsNeeded]="higherRoleIsNeeded"
                                  [readonly]="readonly"
                                  [actionInProgress]="actionInProgress">
                  </app-formswitch>

                  <app-formfileuploadbookar
                            [parentFormGroup]="mainForm"
                            [formType]="formType"
                            [formArrayName]="'bookAr'"
                            [removedArrayName]="'bookArRemoved'"
                            maxFileCount="100"
                            [title]="sts.c('books.ar_items')"
                            [sts]="sts"
                            [permissions]="permissions"
                            [actionInProgress]="actionInProgress"
                            [readonly]="readonly"
                            [metaKeywordsTooltip]="metaKeywordsTooltip">
                    </app-formfileuploadbookar>

                    <app-formfileupload
                            [parentFormGroup]="mainForm"
                            formControlName="coverImage"
                            [control]="coverImage"
                            [title]="sts.c('books.cover_image')"
                            [actionInProgress]="actionInProgress"
                            maxFileSize="20"
                            maxFileCount="1"
                            accept="images"
                            [readonlyProp]="readonly"
                            [acceptRestrictionMessage]="sts.c('validation.image_files_only')"
                            [toolTipOptions]="coverImageToolTipOptions">
                    </app-formfileupload>

                    <app-formfileupload
                            [parentFormGroup]="mainForm"
                            formControlName="contentPreview"
                            [control]="contentPreview"
                            [title]="sts.c('books.content_preview')"
                            [readonlyProp]="readonly"
                            [actionInProgress]="actionInProgress"
                            maxFileSize="20"
                            maxFileCount="1"
                            accept="pdf"
                            [acceptRestrictionMessage]="sts.c('validation.pdf_files_only')">
                    </app-formfileupload>


                    <app-formfileupload
                            [parentFormGroup]="mainForm"
                            formControlName="appImage"
                            [control]="appImage"
                            [title]="sts.c('books.app_image')"
                            [readonlyProp]="readonly"
                            [actionInProgress]="actionInProgress"
                            maxFileSize="20"
                            maxFileCount="1"
                            accept="png"
                            [acceptRestrictionMessage]="sts.c('validation.image_files_only')"
                            [toolTipOptions]="appImageToolTipOptions"
                            (sendSetThumbnailIconByMimeTypeRequest)="setColorPickerBg($event)"
                            (sendDestroyRequest)="removeColorPickerBg()">
                    </app-formfileupload>
                    <app-formcolorpicker
                            [inputType]="'hidden'" [control]="appImageBgColor"
                            [inputName]="'appImageBgColor'" [title]="'books.app_image_bg_color'"
                            [sts]="sts"
                            [parentFormGroup]="mainForm" [permissions]="permissions"
                            [higherRoleIsNeeded]="higherRoleIsNeeded"
                            [readonly]="readonly"
                            [actionInProgress]="actionInProgress"
                            [bgImage]="appImageBgColorBgImage">
                    </app-formcolorpicker>


                    <app-formfileupload
                            [parentFormGroup]="mainForm"
                            formControlName="xmlFile"
                            [control]="xmlFile"
                            [title]="sts.c('books.xml_file')"
                            [readonlyProp]="readonly"
                            [actionInProgress]="actionInProgress"
                            maxFileSize="20"
                            maxFileCount="1"
                            accept="xml">

                    </app-formfileupload>
                    <app-formfileupload
                            [parentFormGroup]="mainForm"
                            formControlName="dataFile"
                            [control]="dataFile"
                            [title]="sts.c('books.data_file')"
                            [readonlyProp]="readonly"
                            [actionInProgress]="actionInProgress"
                            maxFileSize="20"
                            maxFileCount="1"
                            accept="dat">

                    </app-formfileupload>
                    <app-formfileupload
                            [parentFormGroup]="mainForm"
                            formControlName="fullBook"
                            [control]="fullBook"
                            [title]="sts.c('books.full_book')"
                            maxFileSize="20"
                            maxFileCount="1"
                            [readonlyProp]="readonly"
                            [actionInProgress]="actionInProgress"
                            [acceptRestrictionMessage]="sts.c('validation.image_files_only')">

                    </app-formfileupload>



                    <!--
                    <app-formswitch *ngIf="showMaintenanceBtn" [control]="maintenanceControl" [inputName]="'maintenance'"
                                    [title]="'books.maintenance'" [sts]="sts"
                                    [parentFormGroup]="mainForm" [permissions]="permissions"
                                    [higherRoleIsNeeded]="higherRoleIsNeeded"
                                    [toolTipOptions]="maintenanceToolTipOptions"
                                    [readonly]="false"
                                    [actionInProgress]="actionInProgress">
                    </app-formswitch>
                    -->


                    <app-formbuttons [formType]="formType" [actionInProgress]="actionInProgress" [mainForm]="mainForm"
                                     [fileUploadOptions]="fileUploadOptions" (cancelUploadFiles)="cancelUploadFiles($event)"
                                     [readonly]="readonly"
                                     [permissions]="permissions" [tableName]="tableName" [sts]="sts"></app-formbuttons>

                </fieldset>
            </form>
        </div>
    </div>

  <!-- Button trigger modal -->
  <button [hidden]="true" id="opendeleteModal" type="button" data-toggle="modal" data-target="#deleteModal">
    Open Grid Modal
  </button>

  <!-- Modal -->
  <div class="modal fade" #deleteModal data-backdrop="static" data-keyboard="false" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">

        <div class="modal-body text-left">
          <div style="font-size: 22px">{{sts.c('books.under_editing')}}</div>
          <p>{{sts.c('books.under_editing_current_editor')}} {{currentEditor}}</p>
        </div>
        <div class="modal-footer">
          <button id="closedeleteModalHidden" (click)="refreshPage()" type="button" class="btn btn-secondary" data-dismiss="modal">{{sts.st.books.under_editing_ok}}</button>
        </div>
      </div>
    </div>
  </div>

</div>
